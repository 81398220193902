import { MainLayoutContent } from "components/layouts/MainLayoutContent";
import { AppRouteType } from "navigation/registryRoutes";
import slugs from "navigation/slugs";

import React from "react";
const OtherProfile = React.lazy(() => import("../page/OtherProfile"));

const routes: AppRouteType[] = [
  {
    path: slugs.otherProfile,
    component: OtherProfile,
    container: MainLayoutContent,
    isAuthNeeded: true,
  },
  {
    path: slugs.otherProfile,
    component: OtherProfile,
    container: React.Fragment,
    isAuthNeeded: false,
  },
];

export default routes;
