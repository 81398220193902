import {
  getListPostById,
  deletePostById,
  replyPost,
  reactionPost,
  getPostsPublic,
} from "services/apis/apis";
import { Loading } from "components/commons";
import { ACCESS_TOKEN, PROFILE_ID, getItem } from "local-storage";
import {
  ArrowDownTrayIcon,
  ArrowPathRoundedSquareIcon,
  ChatBubbleOvalLeftIcon,
  CreditCardIcon,
  EllipsisVerticalIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import slugs from "navigation/slugs";
import { Modal } from "../Modal";
import { Popover, Transition } from "@headlessui/react";
import { ComingSoon } from "../ComingSoon";
import clsx from "clsx";
import { DEFAULT_IMG } from "config";
import { ReactComponent as Checklist } from "assets/icons/checklist.svg";
// import { currencyFormatter } from "utils/number/number";
import {
  GlobeAmericasIcon,
  HeartIcon,
  UsersIcon,
} from "@heroicons/react/24/solid";
import { HeartIcon as HeartIconOutline } from "@heroicons/react/24/outline";
import { proxy, useSnapshot } from "valtio";
import { ListRepostedUserCard } from "components/z-feed/ListRepostedUserCard";
import { ListLikedUserCard } from "components/z-feed/ListLikedUserCard";
import { ReactComponent as Ellipse1 } from "assets/icons/ellipse1.svg";
import { isBrowser } from "react-device-detect";
import { replaceLinkToTagA } from "helpers/constants";
import { BbChip } from "components/bbchip";
import { Currency } from "../Currency";
import Slider from "react-slick";
import { CopyPostLink } from "../CopyPostLink";
import { SharePostLink } from "../SharePostLink";
import { profileUserProxyService } from "proxy-state/profile-user";
import { CloseModal } from "../Modal/CloseModal";

var settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  dotsClass: "button__bar",
};

export const Post = ({
  id,
  accountShares,
  username,
  isFtFetch,
}: {
  id?: string;
  accountShares?: number;
  username?: string;
  isFtFetch: boolean;
}) => {
  const navigate = useNavigate();
  const profileId = getItem(PROFILE_ID) || null;
  const postState = profileUserProxyService.state.posts?.[id!];
  const accessToken = getItem(ACCESS_TOKEN) || "";
  const [isOpenMenu, setIsOpenMenu] = useState<{ [key: string]: boolean }>();

  const [currentPostIndex, setCurrentPostIndex] = useState<number>(0);
  const [currentSlide, setcurrentSlide] = useState<{
    [key: string]: number;
  }>();
  const [currentPostId, setcurrentPostId] = useState<string>("");

  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenRepostedUsers, setIsOpenRepostedUsersModal] =
    useState<boolean>(false);
  const [isOpenLikedUsers, setIsOpenLikedUsersModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(isFtFetch);
  const [showFullScreen, setShowFullScreen] = useState(false);

  // const { data: listPost, isLoading } = useSnapshot(postState);

  const { posts } = useSnapshot(profileUserProxyService.state);
  const listPost = posts?.[id!]?.data;

  useEffect(() => {
    const f = async () => {
      if (!id) {
        setIsLoading(false);
        return;
      }
      try {
        let result;
        if (!accessToken) {
          result = await getPostsPublic(id);
        } else {
          result = await getListPostById(id);
        }
        profileUserProxyService.setPosts(result, id);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };
    f();
  }, [accessToken, id]);
  useEffect(() => {
    if (!id) return;
    if (listPost === undefined) {
      setIsLoading(true);
      profileUserProxyService.setPosts([], id);
    }
  }, [id, listPost]);

  const handleImageClick = () => {
    setShowFullScreen(true);
  };

  const handleCloseFullScreen = () => {
    setShowFullScreen(false);
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  // const {
  //   data: listPost,
  //   isLoading,
  //   mutate,
  // } = useSWR(id && ["listPost", id], async () => {
  //   if (!id) return;
  //   const result = await getListPostById(id);

  //   const filteredListPost = username
  //     ? result.filter(
  //         (item: any) =>
  //           (accountShares && item.scope === "onlyHolder") ||
  //           item.scope === "everyone"
  //       )
  //     : result;

  //   return filteredListPost;
  // });

  const handleDeletePost = async (index: number) => {
    if (!accessToken) return;
    try {
      const item = listPost[index];
      console.log("🚀 ~ file: index.tsx:109 ~ handleDeletePost ~ item:", item);

      if (!item) return;

      setIsDeleting(true);
      await deletePostById(item.id);

      const filteredListPost = listPost.filter((el: any) => el.id !== item.id);

      postState.data = filteredListPost;
      setIsDeleting(false);
    } catch (error) {
      setIsDeleting(false);
    }
  };

  const download = (url: any, name: any) => {
    if (!url) {
      throw new Error("Resource URL not provided! You need to provide one");
    }
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const blobURL = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = blobURL;
        a.setAttribute("style", "display: none");
        if (name && name.length) a.download = name;
        document.body.appendChild(a);
        a.click();
      })
      .catch(() => {});
  };

  const handleReply = async (index: number) => {
    if (!accessToken) return;
    let item = postState.data[index];

    if (item?.origin_post_id) {
      item = postState.data[index].origin_post;
    } else {
      item = postState.data[index];
    }

    if (!item) return;
    const postId = item?.id;
    if (item.is_repost) return;
    item.is_repost = true;
    item.total_repost = item.total_repost + 1;
    const result = await replyPost(postId);
    if (!result) {
      item.is_repost = false;
      item.total_repost = item.total_repost - 1;
    }
  };

  const handleReaction = async (index: number) => {
    if (!accessToken) return;
    let item = postState.data[index];

    if (item?.origin_post_id) {
      item = postState.data[index].origin_post;
    } else {
      item = postState.data[index];
    }

    if (item.reaction) {
      item.reaction = false;
      item.total_like = item.total_like - 1;
      const result = await reactionPost(item.id);
      if (!result) {
        item.reaction = true;
        item.total_like = item.total_like + 1;
      }
    } else {
      item.reaction = true;
      item.total_like = item.total_like + 1;
      const result = await reactionPost(item.id);
      if (!result) {
        item.reaction = false;
        item.total_like = item.total_like - 1;
      }
    }
  };

  const toggleModalRepostedUsers = () => {
    setIsOpenRepostedUsersModal(!isOpenRepostedUsers);
  };

  const toggleModalLikedUsers = () => {
    setIsOpenLikedUsersModal(!isOpenLikedUsers);
  };
  if (listPost === undefined) return null;
  if (isLoading) {
    return (
      <div className="w-full">
        <Loading.View type={Loading.type.LOADING_POST} limit={5} />;
      </div>
    );
  }

  return (
    <>
      <Modal isOpen={isOpen} toggleModal={toggleModal}>
        <div className="z-50 flex flex-col  p-4 rounded-lg gap-4 shadow justify-center items-center">
          <div className="flex flex-col items-center gap-2">
            <span className=" text-xl text-gray-200">Delete</span>
            <span className="text-base text-gray-200 text-center">
              Do you really want to delete this post? This process cannot be
              undone.
            </span>
          </div>
          <div className="flex flex-col gap-2 w-full">
            <button
              className="rounded-lg btn grow bg-red-500 uppercase border-none text-base font-bold text-gray-200"
              onClick={(e) => {
                e.preventDefault();
                handleDeletePost(currentPostIndex!);
              }}
              disabled={isDeleting}
            >
              {isDeleting ? (
                <span className="flex items-center gap-2">
                  <i className="loading w-5"></i> Deleting...
                </span>
              ) : (
                "Delete"
              )}
            </button>
            <button
              className="rounded-lg grow btn bg-transparent border-2 border-ct-gray-100 uppercase text-gray-200 font-bold"
              onClick={(e) => {
                e.preventDefault();
                toggleModal();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isOpenRepostedUsers}
        toggleModal={toggleModalRepostedUsers}
      >
        <ListRepostedUserCard
          id={currentPostId}
          onClose={toggleModalRepostedUsers}
        />
      </Modal>

      <Modal isOpen={isOpenLikedUsers} toggleModal={toggleModalLikedUsers}>
        <ListLikedUserCard id={currentPostId} onClose={toggleModalLikedUsers} />
      </Modal>

      <div
        id="new-feeds"
        className="flex flex-1 flex-col divide-y divide-gray-800 w-full cursor-pointer"
      >
        {!isLoading && listPost?.length === 0 && (
          <ComingSoon
            type="speaker"
            title="No posts found"
            des={
              username
                ? `Exclusive Post for ${username}’s Accessholders only.`
                : "Click '+' button to create new post"
            }
          />
        )}

        {!isLoading &&
          listPost?.length > 0 &&
          listPost?.map((el: any, index: number) => {
            const item = el?.origin_post_id ? el.origin_post : el;
            const truncatedContent =
              item?.content && item?.content?.length > 280
                ? `${item?.content.slice(
                    0,
                    280
                  )}... <span class="text-secondary">See more</span>`
                : item?.content;
            return (
              <div
                key={el?.id}
                className="w-full border-b border-dk-gray-800 flex flex-col gap-4 py-4"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`${slugs.feed}/${item?.id}`, {
                    state: { from: true },
                  });
                }}
              >
                <div className="flex flex-col gap-1">
                  {el.origin_post_id && (
                    <div className="flex items-center gap-4 w-full text-gray-500 ">
                      <div className="min-w-[52px] flex justify-end text-base">
                        <ArrowPathRoundedSquareIcon className="w-4 h-4" />
                      </div>
                      <div className="text-sm">
                        <span
                          className="text-secondary"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            navigate(`${slugs.profile}/${el?.user_id}`, {
                              state: { from: true },
                            });
                          }}
                        >
                          {el?.twitter_user?.name}
                        </span>
                        <span> Reposted</span>
                      </div>
                    </div>
                  )}
                  <div className="flex items-start gap-4 w-full px-4">
                    <div
                      className="shadow-sm flex justify-center items-center relative"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (profileId === item?.user_id) {
                          navigate(`${slugs.profile}`, {
                            state: { from: true },
                          });
                          return;
                        }
                        navigate(`${slugs.profile}/${item?.user_id}`, {
                          state: { from: true },
                        });
                      }}
                    >
                      {item?.twitter_user?.metadata?.image_url ? (
                        <img
                          className="min-w-[36px] h-9 w-9 shadow-sm rounded-lg object-cover"
                          src={
                            item?.twitter_user?.metadata?.image_url ||
                            DEFAULT_IMG
                          }
                          alt="avatar"
                        />
                      ) : (
                        <img
                          className={clsx(
                            "min-w-[36px] h-9 w-9 shadow-sm rounded-lg object-cover "
                          )}
                          src={DEFAULT_IMG}
                          alt="avatar"
                        />
                      )}
                    </div>

                    <div className="flex flex-col gap-1 grow flex-nowrap text-ellipsis preview_pre_post">
                      <div className="flex-1 flex justify-between gap-1">
                        <div className="flex flex-col items-baseline transition-all truncate grow">
                          <p
                            className={`text-base font-medium flex items-center gap-1`}
                          >
                            <span>{item?.twitter_user?.name}</span>
                            {item?.twitter_user?.is_blue_verified ? (
                              <Checklist className="w-4 h-4" />
                            ) : null}
                            <BbChip userId={item?.user_id} />
                          </p>
                          <div className="w-full flex items-center gap-[1px] text-sm text-gray-500">
                            <div
                              className="flex-nowrap text-ellipsis overflow-hidden"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                navigate(`${slugs.profile}/${item?.user_id}`, {
                                  state: { from: true },
                                });
                              }}
                            >
                              @{item?.twitter_user.username}
                            </div>

                            <div>
                              <Ellipse1 className="w-1 h-1" />
                            </div>

                            <div>
                              <Currency
                                value={item?.user_current_price || 0}
                                isMinimal
                                textClassName="font-normal"
                              />
                            </div>

                            <div>
                              <Ellipse1 className="w-1 h-1" />
                            </div>

                            <div>
                              {moment(item?.created_at)
                                ?.fromNow()
                                ?.replace("ago", "")}
                            </div>

                            <div>
                              <Ellipse1 className="w-1 h-1" />
                            </div>

                            <div className="">
                              {item.scope === "everyone" ? (
                                <GlobeAmericasIcon className="w-4 h-4" />
                              ) : (
                                <UsersIcon className="w-4 h-4" />
                              )}
                            </div>
                          </div>
                        </div>

                        {profileId === el?.user_id ? (
                          <Popover
                            className={clsx("z-50", {
                              "": !isBrowser,
                              relative: isBrowser,
                            })}
                          >
                            <Popover.Button className="inline-flex items-center text-base font-semibold leading-6 text-gray-900 focus:outline-none ring-0">
                              <EllipsisVerticalIcon className="w-6 h-6 stroke-1.5 stroke-slate-500" />
                            </Popover.Button>

                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-150"
                              enterFrom="opacity-0 translate-y-1"
                              enterTo="opacity-100 translate-y-0"
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100 translate-y-0"
                              leaveTo="opacity-0 translate-y-1"
                            >
                              <Popover.Panel className="flex flex-col bg-[#020617] absolute right-4 w-32 rounded-lg transition-opacity duration-300 border border-dk-gray-800 divide-y divide-gray-800 text-slate-300 text-base">
                                <CopyPostLink slug={item?.slug} id={item?.id} />
                                <SharePostLink
                                  // slug={item?.slug}
                                  slug={item?.id}
                                  content={item?.content}
                                />
                                {!el.origin_post_id ? (
                                  <button
                                    className="px-4 py-2.5 text-left"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      navigate(slugs.upload, {
                                        state: item.id,
                                      });
                                    }}
                                  >
                                    Edit
                                  </button>
                                ) : null}

                                <button
                                  className="px-4 py-2.5 text-left text-red-500"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    toggleModal();
                                    setCurrentPostIndex(index);
                                  }}
                                >
                                  Delete
                                </button>
                              </Popover.Panel>
                            </Transition>
                          </Popover>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                {(item.scope === "everyone" || item?.num_holding > 0) && (
                  <>
                    {item?.data?.images?.length ? (
                      <div className="flex-1 flex flex-col gap-4 w-full">
                        <div className="relative">
                          <Slider
                            {...settings}
                            beforeChange={(
                              oldIndex: number,
                              newIndex: number
                            ) => {
                              setcurrentSlide({
                                ...currentSlide,
                                [item.id]: newIndex + 1,
                              });
                            }}
                          >
                            {item?.data?.images.map((el: string) => (
                              <img
                                key={el}
                                alt="post"
                                src={el}
                                className="w-full object-cover shadow"
                                style={{ aspectRatio: "auto 3 / 4" }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleImageClick();
                                  setIsOpenMenu({
                                    ...isOpenMenu,
                                    [item.id]: true,
                                  });
                                }}
                              />
                            ))}
                          </Slider>

                          <div className="absolute right-2 top-2 rounded py-1 px-2 bg-dk-gray-800 text-xs font-bold text-gray-100">
                            {currentSlide && currentSlide[item.id]
                              ? currentSlide[item.id]
                              : 1}
                            /{item?.data?.images.length}
                          </div>
                        </div>

                        {/* {showFullScreen &&
                          isOpenMenu &&
                          isOpenMenu[item.id] && (
                            <div className="fullscreen-overlay active">
                              <div className="fullscreen-image">
                                <div
                                  className="text-sm flex justify-between gap-2 top-4 right-4 left-4 absolute shadow"
                                  onClick={() => {}}
                                >
                                  <XMarkIcon
                                    className="p-2 w-10 h-10 text-gray-100 bg-black shadow rounded-full"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleCloseFullScreen();
                                      setIsOpenMenu({
                                        ...isOpenMenu,
                                        [item.id]: false,
                                      });
                                    }}
                                  />

                                  <div
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      download(
                                        item?.data?.images[0],
                                        `stars-league-${item?.updated_at}.png`
                                      );
                                    }}
                                  >
                                    <ArrowDownTrayIcon className="p-2 w-10 h-10 text-gray-100 bg-black shadow rounded-full" />
                                  </div>
                                </div>
                                <img
                                  src={DEFAULT_FEED_IMG}
                                  // src={item?.data?.images[0]}
                                  alt="Full Screen"
                                />
                              </div>
                            </div>
                          )} */}
                      </div>
                    ) : null}
                    <div className="flex-1 flex flex-col gap-4 w-full">
                      {item?.content && (
                        <div
                          className="text-base whitespace-pre-line break-words px-4"
                          dangerouslySetInnerHTML={{
                            __html: replaceLinkToTagA(truncatedContent),
                          }}
                        ></div>
                      )}

                      <div className="flex justify-between px-4">
                        <div className="flex gap-4 items-center">
                          <button className="flex items-center text-gray-500 gap-2">
                            <ChatBubbleOvalLeftIcon className="w-4 h-4 stroke-2" />
                            <span className="text-sm">
                              {item?.total_comment || 0}
                            </span>
                          </button>
                          <button
                            className={clsx(
                              "flex items-center text-gray-500 gap-2",
                              { "text-green-600": !!item?.is_repost }
                            )}
                          >
                            <ArrowPathRoundedSquareIcon
                              className={clsx("w-4 h-4", {
                                "text-green-600": !!item?.is_repost,
                              })}
                              onClick={(e) => {
                                e.stopPropagation();

                                if (item?.is_repost) return;
                                handleReply(index);
                              }}
                            />
                            <span
                              className={clsx("text-sm", {
                                "text-green-600": !!item?.is_repost,
                              })}
                              onClick={(e) => {
                                e.stopPropagation();
                                setcurrentPostId(item?.id);
                                toggleModalRepostedUsers();
                              }}
                            >
                              {item?.total_repost || 0}
                            </span>
                          </button>
                          <button className="flex items-center text-gray-500 gap-2">
                            {item?.reaction ? (
                              <HeartIcon
                                className={clsx(
                                  "w-4 h-4 stroke-2 text-red-500"
                                )}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleReaction(index);
                                }}
                              />
                            ) : (
                              <HeartIconOutline
                                className="w-4 h-4 stroke-2"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleReaction(index);
                                }}
                              />
                            )}
                            <span
                              className="text-sm"
                              onClick={(e) => {
                                e.stopPropagation();
                                setcurrentPostId(item?.id);
                                toggleModalLikedUsers();
                              }}
                            >
                              {item?.total_like || 0}
                            </span>
                          </button>
                        </div>
                        {/* <div className="flex gap-1 cursor-pointer">
                            <BanknotesIcon
                              onClick={(e) => {
                                e.stopPropagation();
                                setcurrentPostId(item?.id);
                                toggleModalTipUsers();
                              }}
                              className={clsx("w-4 h-4 text-primary-focus")}
                            />
                            <div
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setAddressDonate(item?.user_address);

                                setIndexDonate(index);
                                toggleModalDonate();
                              }}
                              className="text-sm text-primary-focus"
                            >
                              {item?.total_tip} CHZ
                            </div>
                          </div> */}
                      </div>
                    </div>
                  </>
                )}

                {item.scope !== "everyone" && !item?.num_holding && (
                  <div>
                    <div className="px-4 flex items-center text-sm text-[#F97316]">
                      <span>This feed is only visible to accessholders</span>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </>
  );
};
