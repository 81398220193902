import { ReactComponent as Gift } from "assets/icons/coming-soon.svg";
import { IS_REQUIRED_RE_OPEN_PWA, saveItem } from "local-storage";

export const UpdateVersion = (props: any) => {
  return (
    <div className="p-4 h-[100dvh]">
      <div className="flex flex-col justify-center items-center gap-4 py-6 h-full">
        <div className="gap-2 flex flex-col justify-center items-center">
          <Gift className="w-24" />
          <div className="text-xl text-center font-medium">
            New version available
          </div>

          <div className="border rounded-lg py-3 px-6 border-dk-gray-800 my-2 text-slate-300">
            <div className="w-full text-center font-medium text-lg mb-2">
              What's new?
            </div>

            <div className="flex items-center icon-primary flex-shrink-0 gap-2">
              <i
                className="fa-duotone fa-pen-ruler text-xl leading-none"
                aria-hidden="true"
              ></i>
              <span className="text-slate-300">
                Full support for Desktop & Web versions
              </span>
            </div>

            <div className="flex items-center icon-primary flex-shrink-0 gap-2">
              <i
                className="fa-duotone fa-pen-ruler text-xl leading-none"
                aria-hidden="true"
              ></i>
              <span className="text-slate-300">
                Direct messaging now available
              </span>
            </div>

            <div className="flex items-center icon-primary flex-shrink-0 gap-2 mb-1">
              <i
                className="fa-duotone fa-bug text-xl leading-none"
                aria-hidden="true"
              ></i>
              <span className="text-slate-300">
                Minor improvements & bug fixes
              </span>
            </div>
          </div>
          <div className="text-base text-center text-slate-300 ">
            <span>Click 👇</span>
          </div>
          <button
            className="rounded-lg btn bg-secondary w-full outline-none"
            onClick={() => {
              saveItem(IS_REQUIRED_RE_OPEN_PWA, true);
              window?.location.reload();
            }}
          >
            Stars League {props?.version} version
          </button>
        </div>
      </div>
    </div>
  );
};
