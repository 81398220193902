// export const DOMAIN =
//   process.env.REACT_APP_APP_ENV === "production"
//     ? "https://u.fan.tech/f"
//     : `https://fantech-seo-testing-ro3eo5e2oq-uc.a.run.app/f`;
// export const DOMAIN_PROFILE =
//   process.env.REACT_APP_APP_ENV === "production"
//     ? "https://u.fan.tech/"
//     : `https://fantech-seo-testing-ro3eo5e2oq-uc.a.run.app`;

export const DOMAIN = window.location.host;
export const DOMAIN_PROFILE = window.location.host;

export const copyPostSlug = (slug: string) => {
  try {
    // navigator.clipboard.writeText(`${DOMAIN}/${slug}`);
    navigator.clipboard.writeText(`${DOMAIN}/feed/${slug}`);

    return true;
  } catch (error) {
    return false;
  }
};
