const BASE_URL = process.env.REACT_APP_BASE_URL || "";

export const uploadPostUrl = `${BASE_URL}/post`;
export const upLoadMediaUrlPayView = `${BASE_URL}/post/upload-media`;

export const pushCommentUrl = `${BASE_URL}/post/comment`;
export const detailPostUrl = (id: string) => `${BASE_URL}/post/${id}`;
export const deleteCommentByIdUrl = (id: string) => `${BASE_URL}/comment/${id}`;
export const getCommentsByPostIdUrl = (id: string) =>
  `${BASE_URL}/post/${id}/comments`;
export const getRepostedUsersByPostIdUrl = (id: string) =>
  `${BASE_URL}/post/${id}/reposted-users`;
export const getTipUsersByPostIdUrl = (id: string) =>
  `${BASE_URL}/post/${id}/tips`;
export const getLikedUsersPostIdUrl = (id: string) =>
  `${BASE_URL}/post/${id}/liked-users`;
export const postForYouUrl = `${BASE_URL}/post/for-you`;
export const postHotUrl = `${BASE_URL}/post/hot`;
export const postRecentUrl = `${BASE_URL}/post/recent`;
export const post33Url = `${BASE_URL}/post/buyback`;
export const getListPostByIdUrl = (id: string) => `${BASE_URL}/post/user/${id}`;
export const getPostReactionUrl = `${BASE_URL}/post/reaction`;
export const getCommentReactionUrl = `${BASE_URL}/comment/reaction`;
export const getLikedUsersByCommentIdUrl = (id: string) =>
  `${BASE_URL}/comment/${id}/liked-users`;
export const getPostRepostUrl = (id: string) => `${BASE_URL}/post/${id}/repost`;
export const detailPostPublicUrl = (id: string) =>
  `${BASE_URL}/public/post/${id}`;
export const getCommentsByPostIdPublicUrl = (id: string) =>
  `${BASE_URL}/post/public/${id}/comments`;
export const getExploreUrl = `${BASE_URL}/user/search`;
export const getInviteCodeUrl = `${BASE_URL}/user/get-invite-code`;
export const getInviteCodePremiumUrl = `${BASE_URL}/user/get-invite-code-premium`;
export const renewInviteCodeUrl = `${BASE_URL}/user/renew-invite-code`;
export const searchFantechUsersUrl = `${BASE_URL}/user/search-fantech`;
export const getUserByIdUrl = (id: string) => `${BASE_URL}/user/${id}/detail`;
export const getUserHoldingByIdUrl = (id: string) =>
  `${BASE_URL}/user/${id}/holdings`;
export const getUserSubscribersByIdUrl = (id: string) =>
  `${BASE_URL}/user/${id}/subscribers`;
export const getUserHoldersByIdUrl = (id: string) =>
  `${BASE_URL}/user/${id}/holders`;
export const getUserHolderIdsUrl = (id: string) =>
  `${BASE_URL}/user/${id}/holder-ids`;
export const getUserHoldingIdsUrl = (id: string) =>
  `${BASE_URL}/user/${id}/holding-ids`;

export const getUserBiddingsByIdUrl = (id: string) =>
  `${BASE_URL}/user/${id}/bidding`;
export const getUserActivitiesByIdUrl = (id: string) =>
  `${BASE_URL}/user/${id}/activity`;
export const getUsersByIdUrl = (id: string) => `${BASE_URL}/user/${id}/detail`;
export const getUserTradeByIdUrl = (id: string) =>
  `${BASE_URL}/user/${id}/trade`;
export const getAllBidsUrl = (id: string) => `${BASE_URL}/user/${id}/bid`;

export const getNotificationUrl = () => `${BASE_URL}/notification/get-list`;
export const getNotificationHistoryUrl = `${BASE_URL}/notification/history`;
export const updateStatusNotificationUrl = (id: string) =>
  `${BASE_URL}/notification/${id}`;
export const updateAllowNotificationUrl = () =>
  `${BASE_URL}/notification/update-user-notification`;
export const getCountNotiUrl = () => {
  return `${BASE_URL}/notification/history/unread-count`;
};
export const putNotiViewUrl = (notiId: string) => {
  return `${BASE_URL}/notification/history/${notiId}/view`;
};
export const getHistoryBidsUrl = (id: string) =>
  `${BASE_URL}/user/${id}/bid-history`;

export const getSubscribedUrl = `${BASE_URL}/user/subscribe-action`;
export const getAuthUrl = `${BASE_URL}/auth`;
export const getApps = `${BASE_URL}/app`;

export const getUserUrl = `${BASE_URL}/user`;
export const getUserProfileUrl = `${BASE_URL}/user/profile`;
export const getUserHoldingsStatisticUrl = `${BASE_URL}/user/holdings-statistic`;
export const getUserSubscriptionsUrl = `${BASE_URL}/user/subscriptions`;
export const getInviteesUrl = `${BASE_URL}/user/referral-share`;
export const getReferralStatisticUrl = `${BASE_URL}/user/referral-share-statistic`;
// export const getUserStatisticUrl = `${BASE_URL}/user/statistic`;
export const updateInviteCodeUrl = `${BASE_URL}/user/update-invite-code`;
export const postTipUrl = `${BASE_URL}/post/ttt`;
export const delegateInitializeShares = `${BASE_URL}/auth/delegate-initialize-shares`;
export const getTwitterAuthUrl = `${BASE_URL}/auth/login/twitter-auth-url`;
export const postLinkTwitterUrl = `${BASE_URL}/auth/login/link-twitter`;
export const confirmTiktokCodeUrl = `${BASE_URL}/auth/confirm-tiktok-code`;
export const configTiktokUrl = `${BASE_URL}/admin-config/tiktok-config`;
export const getTiktokFollowingUrl = `${BASE_URL}/tiktok-following`;
export const getFollowingUsersUrl = `${BASE_URL}/user/following`;
export const getTiktokUserTrendingUsersUrl = `${BASE_URL}/user/trending-users`;
export const getTrendingUsersUrl = `${BASE_URL}/user/trending-users`;
export const getBuyBack33UsersUrl = `${BASE_URL}/user/buyback`;
export const getTrendingUsersDailyUrl = `${BASE_URL}/user/trending-users-daily`;
export const getLeaderBoardTradersUrl = `${BASE_URL}/user/leader-board/traders`;
export const getLeaderBoardWhalesUrl = `${BASE_URL}/user/leader-board/whales`;
export const getLeaderBoardPointsUrl = `${BASE_URL}/point/leaderboard`;
export const getLeaderBoardPointsDailyUrl = `${BASE_URL}/point/leaderboard/24h`;

export const getNewUsersUrl = `${BASE_URL}/user/new-users`;
export const getTiktokUserUpcomingUsersUrl = `${BASE_URL}/user/upcoming-users`;
export const getUpcomingUsersUrl = `${BASE_URL}/user/upcoming-users`;
export const getSGEUsersUrl = `${BASE_URL}/user/sge`;
export const getSGELiveUsersUrl = `${BASE_URL}/user/sge/live`;
export const getSubscribeUrl = `${BASE_URL}/subscription/subscribe`;
export const getunsubscribeUrl = `${BASE_URL}/subscription/unsubscribe`;
export const checkSubscribersUrl = `${BASE_URL}/subscription/check-subscriber`;
export const getLiveInfoUrl = `${BASE_URL}/chat/user-info`;
export const getChatSession = `${BASE_URL}/user/chat-session`;
export const getGroupInfoUrl = `${BASE_URL}/chat/group-owner-info`;
export const getGroupParnerInfoUrl = `${BASE_URL}/chat/group-partner-info`;
export const getClientVersionUrl = `${BASE_URL}/admin-config/client-version`;
export const getCheckSGELiveUrl = `${BASE_URL}/user/check-sge-live`;

export const postConfigSgeUrl = `${BASE_URL}/user/config-sge`;

export const getPoolInitialBuyPriceAfterFeeUrl = (id: string) =>
  `${BASE_URL}/block-chain/${id}/pool-initial-buy-price-after-fee`;

export const getBuyPriceAfterFeeUrl = (id: string) =>
  `${BASE_URL}/block-chain/${id}/buy-price-after-fee`;

export const getRawDataSwapEthToMntUrl = `${BASE_URL}/block-chain/swap/eth-to-mnt`;
export const getRawDataSwapMntToEthUrl = `${BASE_URL}/block-chain/swap/mnt-to-eth`;
export const postAddMemberToGroupChat = `${BASE_URL}/block-chain/add-member-to-group-chat`;
export const createDirectMessageGroupUrl = `${BASE_URL}/sendbird/create-direct-message-group`;
export const getMessageUserUnreadUrl = `${BASE_URL}/sendbird/get-message-user-unread`;

export const getACategoryUrl = `${getApps}/a-category`;
export const getAppUsedByIdUrl = (id: string) => `${getApps}/used/${id}`;

// user -> method ->

//public
export const getProfilePublicUrl = (id: string) => {
  return `${BASE_URL}/public/user/${id}`;
};

export const getPostsPublicUrl = (id: string) => {
  return `${BASE_URL}/public/posts/${id}`;
};
